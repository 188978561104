<style scoped lang="scss">
@import 'style/vars';
</style>

<template>
    <modal
        :show="show"
        @hide="show = false"
        :showClose="false"
        width="600px"
        maxWidth="80vw"
    >
        <template #title>{{ $t('frgtPwd.title') }}</template>
        <template #content>
            <template v-if="showSuccess">
                <div>{{ $t('frgtPwd.rqsRndmPwd.success') }}</div>
            </template>
            <template v-else>
                <div>{{ $t('frgtPwd.remark1') }}</div>
                <div class="mt-3">
                    <field
                        class="w-100"
                        :label="$t('frgtPwd.emailAddr')"
                        :error="errors.emailAddr"
                    >
                        <input
                            class="form-control"
                            v-model="formData.emailAddr"
                        />
                    </field>
                </div>
            </template>
        </template>
        <template #footer>
            <template v-if="showSuccess">
                <div class="d-flex w-100">
                    <div class="mr-auto"></div>
                    <button class="btn btn-primary px-3" @click="show = false">
                        {{ $t('common.ok') }}
                    </button>
                </div>
            </template>
            <template v-else>
                <errorBox :errors="saveErrors" />
                <div class="d-flex w-100">
                    <div class="mr-auto">
                        <button
                            class="btn btn-outline-secondary px-3"
                            @click="show = false"
                        >
                            {{ $t('common.cancel') }}
                        </button>
                    </div>
                    <savingButton
                        class="btn btn-primary px-3"
                        :state="saveState"
                        @click="save"
                        @saved="saved"
                    >
                        {{ $t('frgtPwd.sendRndmPwdEmail') }}
                    </savingButton>
                </div>
            </template>
        </template>
    </modal>
</template>

<script>
import modal from 'common-vue/modal';
import savingButton from 'common-vue/savingButton';
import errorBox from '@/components/errorBox.vue';
import field from 'common-vue/field';
import { validate } from 'common-vue/validationUtil';
import { flowValidate, validRequired, validEmail } from '@/utils/dataValidator';
import { post } from '@/utils/ajax';

const rqsRndmPwd = async (data) => {
    return await post('/api/userFrgtPwd/rqsRndmPwd', data);
};

const createInitData = () => ({
    show: false,
    formData: {},
    errors: {},
    saveErrors: {},
    saveState: '',
    showSuccess: false
});

export default {
    components: {
        modal,
        savingButton,
        errorBox,
        field
    },
    props: {},
    data() {
        return createInitData();
    },
    computed: {},
    methods: {
        async prompt() {
            Object.assign(this, {
                ...createInitData(),
                show: true
            });
        },
        async save() {
            this.saveState = 'saving';
            this.errors = {};
            this.saveErrors = {};

            const v = validate(this.errors, this.formData, {
                emailAddr: {
                    checked: (emailAddr) => {
                        return flowValidate(
                            validRequired,
                            validEmail
                        )(emailAddr);
                    }
                }
            });

            if (v.hasError) {
                this.saveState = '';
                this.errors = v.errors;
                return;
            }

            await rqsRndmPwd({ ...this.formData });
            this.saveState = 'saved';
        },
        saved() {
            this.showSuccess = true;
        }
    }
};
</script>
