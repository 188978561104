const CnndOptTypeCode = {
    IssueType: 'ISS',
    Source: 'SRC',
    Location: 'LOC',
    Reporter: 'RPTR'
};

const UserAcctStsCode = {
    Active: 'A',
    Inactive: 'I'
};

const SiteStsCode = {
    Active: 'A',
    Inactive: 'I'
};

const CaseStatus = {
    New: {
        label: 'New',
        code: 'N',
        color: '#e61610',
        icon: 'error_outline.svg'
    },
    InProgress: {
        label: 'In-Progress',
        code: 'P',
        color: '#ffa834',
        icon: 'timelapse.svg'
    },
    Resolved: {
        label: 'Resolved',
        code: 'R',
        color: '#4472c4',
        icon: 'check_circle_outline.svg'
    },
    FurtherAction: {
        label: 'Further Action',
        code: 'F',
        color: '#a568d2',
        icon: 'pending.svg'
    },
    Closed: {
        label: 'Closed',
        code: 'C',
        color: '#629c44'
        // icon: ''
    },
    Cancelled: {
        label: 'Cancelled',
        code: 'X',
        color: ''
        // icon: ''
    }
};

const PrvlCode = {
    Dashboard: 'DSBD',
    CaseManagement: 'CASE_MGT',
    CaseManagementFollowUp: 'CASE_MGT_FLUP',
    CaseManagementFollowUpReopen: 'CASE_MGT_FLUP_REOPEN',
    CaseManagementFollowUpClose: 'CASE_MGT_FLUP_CLOSE',
    CaseManagementFollowUpCancel: 'CASE_MGT_FLUP_CANCEL',
    UserSetup: 'USER_SETUP',
    SiteSetup: 'SITE_SETUP',
    CannedOptionSetup: 'CNND_OPT_SETUP',
    SystemSetup: 'SYS_SETUP',
    MyProfile: 'MY_PRFL'
};

const PrvlActCode = {
    Write: 'WRITE',
    Read: 'READ'
};

export {
    CnndOptTypeCode,
    UserAcctStsCode,
    SiteStsCode,
    CaseStatus,
    PrvlCode,
    PrvlActCode
};
