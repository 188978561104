import escapeRegExp from 'lodash/escapeRegExp';
import { formatTz } from '@/utils/dateUtil';
import addMonths from 'date-fns/addMonths';
import { numberToDate } from '@/utils/dateUtil';
import { getMessage } from 'common-vue/message';

const $t = getMessage;

function isEmptyValue(val) {
    return val === null || val === undefined || val === '';
}

function flowValidate(...vs) {
    return (val) => {
        for (const v of vs) {
            const msg = v(val);
            if (msg) {
                return msg;
            }
        }
    };
}

function flowValidateAsync(...vs) {
    return async (val) => {
        for (const v of vs) {
            const msg = await v(val);
            if (msg) {
                return msg;
            }
        }
    };
}

function validRequired(val) {
    if (isEmptyValue(val)) {
        return 'error.required';
    }
}

function validEmail(val) {
    if (isEmptyValue(val)) {
        return undefined;
    }
    const emailAddrRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailAddrRegex.test(val)) {
        return 'error.email.format';
    }
}

/*
Password Policy
1. Minimum length: 8 characters
2. Minimum complexity: composed of at least 3 of the following 4 types of characters:
   i. Alphabets (upper case), e.g. A,B,C,D,…
   ii. Alphabets (lower case), e.g. a,b,c,d,…
   iii. Numbers, e.g. 1,2,3,4,…
   iv. Symbols, e.g. !,@,#,$,%,^,&,*,…
*/
function validUserPassword(val) {
    if (isEmptyValue(val)) {
        return undefined;
    }
    if (val === null || val === undefined || val === '') {
        return 'error.userPassword.invalid';
    }

    if (val.length < 8) {
        return 'error.userPassword.invalid';
    }

    const checkCharacter = (regex, min) => {
        const m = val.match(regex);
        return m && m.length >= min;
    };
    const complexity = [
        checkCharacter(/[A-Z]/g, 1),
        checkCharacter(/[a-z]/g, 1),
        checkCharacter(/[0-9]/g, 1),
        checkCharacter(
            new RegExp(
                '[' + escapeRegExp('!"#$%&\'()*+,-./:;<=>?@[\\]^_`{|}~') + ']',
                'g'
            ),
            1
        )
    ].filter((b) => b).length;
    if (complexity < 3) {
        return 'error.userPassword.invalid';
    }
}

function getValidUniqueInList(list, getValue) {
    return (val) => {
        if (isEmptyValue(val)) {
            return undefined;
        }
        if (list.filter((v) => getValue(v) === val).length > 1) {
            return 'error.shouldUnique';
        }
    };
}

function validPhoneNum(val) {
    if (isEmptyValue(val)) {
        return undefined;
    }
    if (!/^[0-9]{8}$/.test(val)) {
        return 'error.phoneNum.invalid';
    }
}

function validBankCode(val) {
    if (isEmptyValue(val)) {
        return undefined;
    }
    if (!/^[0-9]{3}$/.test(val)) {
        return 'error.bankCode.invalid';
    }
}

/*
    - only allow digit and '-'
    - length <= 16
    - length of digit <= 12
*/
function validAcctNum(val) {
    if (isEmptyValue(val)) {
        return undefined;
    }
    if (
        !/^[0-9-]{1,16}$/.test(val) ||
        !/^[0-9]{1,12}$/.test(val.replaceAll('-', ''))
    ) {
        return 'error.acctNum.invalid';
    }
}

function getValidDateRange(from, to) {
    return (val) => {
        if (val < from || val > to) {
            return ['error.dateRange', formatTz(from), formatTz(to)];
        }
    };
}

function dateRangeWithinMonths(from, to, months, required = false) {
    if (required && (!from || !to)) {
        return 'error.required';
    }

    if (
        (!from && to) ||
        (from && !to) ||
        addMonths(numberToDate(from), months) < numberToDate(to)
    ) {
        return $t('error.dataRange.within.months', months);
    }
}

function validSitePrfx(val) {
    if (isEmptyValue(val)) {
        return 'error.required';
    }

    const sitePrfxRegex = /^[A-Z]$/;
    if (!sitePrfxRegex.test(val)) {
        return 'error.site.prefix.invalid';
    }
}

export {
    flowValidate,
    flowValidateAsync,
    validRequired,
    validEmail,
    validUserPassword,
    getValidUniqueInList,
    validPhoneNum,
    validBankCode,
    validAcctNum,
    getValidDateRange,
    dateRangeWithinMonths,
    validSitePrfx
};
