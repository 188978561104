import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/Home.vue';
import Login from '../views/login/Login.vue';
import Logout from '../views/logout/Logout.vue';
import AccessDenied from '../views/AccessDenied.vue';
import pageLoader from '@/components/pageLoader.vue';
import store from '../store';
import get from 'lodash/get';
import { PrvlActCode, PrvlCode } from '@/constant';

const addEditTitle = (path) => () =>
    get(store.state, path + '._new') === true ? 'common.add' : 'common.edit';

const lazyLoadView = function (asyncView) {
    const AsyncHandler = () => ({
        component: asyncView,
        loading: pageLoader
    });
    return Promise.resolve({
        functional: true,
        render(h, { data, children }) {
            return h(AsyncHandler, data, children);
        }
    });
};

const routes = [
    {
        path: '/login',
        name: 'login',
        meta: { noMenu: true },
        component: Login
    },
    {
        path: '/logout',
        name: 'logout',
        meta: { noMenu: false },
        component: Logout
    },
    {
        path: '/',
        name: 'home',
        meta: { title: 'Home' },
        component: Home
    },
    {
        path: '/accessDenied',
        name: 'accessDenied',
        meta: { title: 'Access Denied' },
        component: AccessDenied
    },

    /*new-route-injection-point*/
    {
        path: '/dashboard',
        name: 'dashboard',
        meta: {
            titleMsgKey: 'dashboard.title',
            pvl: addPrvlSuffix(PrvlCode.Dashboard, PrvlActCode.Read)
        },
        component: () =>
            lazyLoadView(
                import(
                    /* webpackChunkName: "dashboard" */ '../views/dashboard/Dashboard.vue'
                )
            )
    },
    {
        path: '/case',
        name: 'case',
        meta: {
            titleMsgKey: 'case.title',
            pvl: addPrvlSuffix(PrvlCode.CaseManagement, PrvlActCode.Read)
        },
        component: () =>
            lazyLoadView(
                import(/* webpackChunkName: "case" */ '../views/case/Case.vue')
            ),
        children: [
            {
                name: 'caseEdit',
                path: 'edit',
                meta: { titleMsgKey: addEditTitle('case.editData') },
                beforeEnter: (to, from, next) =>
                    next(
                        get(store.state, 'case.editData') ? undefined : '/case'
                    ),
                component: () =>
                    lazyLoadView(
                        import(
                            /* webpackChunkName: "case"*/ '../views/case/CaseEdit.vue'
                        )
                    )
            }
        ]
    },
    {
        path: '/user',
        name: 'user',
        meta: {
            titleMsgKey: 'user.title',
            pvl: addPrvlSuffix(PrvlCode.UserSetup, PrvlActCode.Read)
        },
        component: () =>
            lazyLoadView(
                import(/* webpackChunkName: "user" */ '../views/user/User.vue')
            ),
        children: [
            {
                name: 'userEdit',
                path: 'edit',
                meta: { titleMsgKey: addEditTitle('user.editData') },
                beforeEnter: (to, from, next) =>
                    next(
                        get(store.state, 'user.editData') ? undefined : '/user'
                    ),
                component: () =>
                    lazyLoadView(
                        import(
                            /* webpackChunkName: "user"*/ '../views/user/UserEdit.vue'
                        )
                    )
            }
        ]
    },
    {
        path: '/report',
        name: 'report',
        meta: {
            titleMsgKey: 'report.title'
            // pvl: addPrvlSuffix(PrvlCode.UserSetup, PrvlActCode.Read)
        },
        component: () =>
            lazyLoadView(
                import(/* webpackChunkName: "report" */ '../views/rpt/Rpt.vue')
            ),
        children: [
            {
                path: 'caseSummaryReport',
                name: 'caseSmryRpt',
                meta: { titleMsgKey: 'report.title.caseSmryRpt' },
                component: () =>
                    lazyLoadView(
                        import(
                            /* webpackChunkName: "report"*/ '../views/rpt/RptCaseSmry.vue'
                        )
                    )
            },
            {
                path: 'caseDetailReport',
                name: 'caseDtlRpt',
                meta: { titleMsgKey: 'report.title.caseDtlRpt' },
                component: () =>
                    lazyLoadView(
                        import(
                            /* webpackChunkName: "report"*/ '../views/rpt/RptCaseDtl.vue'
                        )
                    )
            }
        ]
    },
    {
        path: '/site',
        name: 'site',
        meta: {
            titleMsgKey: 'site.title',
            pvl: addPrvlSuffix(PrvlCode.SiteSetup, PrvlActCode.Read)
        },
        component: () =>
            lazyLoadView(
                import(/* webpackChunkName: "site" */ '../views/site/Site.vue')
            ),
        children: [
            {
                name: 'siteEdit',
                path: 'edit',
                meta: {
                    titleMsgKey: addEditTitle('site.editData')
                },
                beforeEnter: (to, from, next) =>
                    next(
                        get(store.state, 'site.editData') ? undefined : '/site'
                    ),
                component: () =>
                    lazyLoadView(
                        import(
                            /* webChunkName: "site" */ '../views/site/SiteEdit.vue'
                        )
                    )
            }
        ]
    },
    {
        path: '/cannedOption',
        name: 'cnndOpt',
        meta: {
            titleMsgKey: 'cnndOpt.title',
            pvl: addPrvlSuffix(PrvlCode.CannedOptionSetup, PrvlActCode.Read)
        },
        component: () =>
            lazyLoadView(
                import(
                    /* webpackChunkName: "cnndOpt" */ '../views/cnndOpt/CnndOpt.vue'
                )
            ),
        children: [
            {
                name: 'cnndOptEdit',
                path: 'edit',
                meta: {
                    titleMsgKey: addEditTitle('cnndOpt.editData')
                },
                beforeEnter: (to, from, next) =>
                    next(
                        get(store.state, 'cnndOpt.editData')
                            ? undefined
                            : '/cannedOption'
                    ),
                component: () =>
                    lazyLoadView(
                        import(
                            /* webChunkName: "cnndOpt" */ '../views/cnndOpt/CnndOptEdit.vue'
                        )
                    )
            }
        ]
    },
    {
        path: '/systemSetup',
        name: 'sysSetup',
        meta: {
            titleMsgKey: 'sysSetup.title',
            pvl: addPrvlSuffix(PrvlCode.SystemSetup, PrvlActCode.Read)
        },
        component: () =>
            lazyLoadView(
                import(
                    /* webpackChunkName: "sysSetup" */ '../views/sysSetup/SysSetup.vue'
                )
            )
    },
    {
        path: '/myProfile',
        name: 'myPrfl',
        meta: {
            titleMsgKey: 'myPrfl.title',
            pvl: addPrvlSuffix(PrvlCode.MyProfile, PrvlActCode.Read)
        },
        component: () =>
            lazyLoadView(
                import(
                    /* webpackChunkName: "myPrfl" */ '../views/myPrfl/MyPrfl.vue'
                )
            )
    }
];

const permitPages = [
    //public page
    {
        match: () => true,
        paths: []
    },
    {
        match: () => !store.state.srcUser,
        redirect: () => '/',
        paths: ['/login']
    },
    {
        match: () => store.state.srcUser,
        redirect: () => ({ name: 'login' }),
        paths: '*'
    }
];

function addPrvlSuffix(pvl, suffix) {
    return pvl.concat('_', suffix);
}

export default async function createRouter() {
    Vue.use(VueRouter);
    const router = new VueRouter({
        mode: 'history',
        base: process.env.BASE_URL,
        routes
    });

    router.beforeEach((to, from, next) => {
        if (to.meta && to.meta.pvl && !store.getters.hasPvl(to.meta.pvl)) {
            next('/accessDenied');
        }
        permitPages.some((p) => {
            if (
                p.paths === '*' ||
                p.paths.some((_p) => to.path.indexOf(_p) === 0)
            ) {
                if (p.match()) {
                    next();
                } else {
                    next(p.redirect(to, from));
                }
                return true;
            }
        });
    });

    if (store.getters.hasProfile('dev', 'local', 'demo')) {
        router.addRoute((await import('./demoRoutes')).default);
    }
    return router;
}
