<style lang="scss" scoped>
@import 'style/vars';
.breadcrumb-item {
    font-size: $font-size-base;
    font-weight: $font-weight-bold;
}
.sep {
    line-height: 1.1;
}
</style>
<template>
    <div class="d-flex flex-column h-100">
        <div class="d-flex mt-3 ml-3">
            <div
                class="d-flex items-center"
                v-for="(r, ri) in routes"
                :key="ri"
            >
                <h3 class="breadcrumb-item">
                    <router-link :to="r.path" v-if="ri < routes.length - 1">
                        {{ r.title }}
                    </router-link>
                    <span v-else> {{ r.title }}</span>
                </h3>
                <div class="sep mx-1" v-if="ri < routes.length - 1">&gt;</div>
            </div>
        </div>
        <div class="container-fluid flex-extend">
            <slot></slot>
        </div>
    </div>
</template>

<script>
import { getMessage } from 'common-vue/message';
import isFunction from 'lodash/isFunction';

const getStr = (s) => (isFunction(s) ? s() : s);

const getTitle = (r) => {
    if (!r.meta) {
        return '';
    }
    if (r.meta.titleMsgKey) {
        return getMessage(getStr(r.meta.titleMsgKey));
    }
    if (r.meta.title) {
        return getStr(r.meta.title);
    }
    return '';
};
export default {
    computed: {
        routes() {
            return this.$route.matched.map((r) => ({
                title: getTitle(r),
                path: r.path,
                name: r.name
            }));
        }
    }
};
</script>
