<style lang="scss">
@import 'style/base';
</style>

<style lang="scss" scoped>
@import 'style/vars';
.app {
    display: grid;
    display: -ms-grid;
    grid-template-columns: auto 1fr;
    -ms-grid-columns: auto 1fr;
    grid-template-rows: auto 1fr;
    -ms-grid-rows: auto 1fr;
    min-height: 100vh;
}
header {
    grid-column: 1 / 3;
    -ms-grid-column: 1;
    -ms-grid-column-span: 2;
    line-height: 1;
}
nav {
    -ms-grid-row: 2;
    -ms-grid-column: 1;
    -ms-grid-column-span: 1;
    position: relative;
}
main {
    -ms-grid-row: 2;
    -ms-grid-column: 2;
    -ms-grid-column-span: 1;
    overflow: hidden;
    background-color: $gray-200;
}
.app.no-menu ::v-deep main {
    grid-column: 1 / 3;
    -ms-grid-column: 1;
    -ms-grid-column-span: 2;
}
.nav-title {
    font-size: $font-size-lg;
    font-weight: 500;
}

.app.has-drawer ::v-deep header {
    position: relative;
}

.app.has-drawer ::v-deep header::before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.5);
}
</style>

<template>
    <div
        id="app"
        class="app"
        :class="{ 'no-menu': $route.meta.noMenu }"
        v-on-interaction="updateSession"
    >
        <header
            class="header border-bottom border-primary p-2 d-flex align-items-center"
        >
            <div class="nav-title text-primary mr-5 d-flex align-items-center">
                <img
                    class="logo ml-3 mr-4"
                    :src="logoImg"
                    alt="GammonPR"
                    style="max-height: 2rem"
                />
                {{ $t('site.name') }}
            </div>
            <template v-if="userName">
                <div class="greeting">
                    {{ $t('header.greeting', userName) }}
                </div>
                <div class="last-login ml-auto">
                    {{ $t('header.last.login', loginAt) }}
                    <button
                        class="logout ml-5 btn p-0 text-primary"
                        @click="logout"
                        :title="$t('login.logout')"
                    >
                        <icon class="icon-lg mr-1" src="logout.svg" />
                    </button>
                </div>
            </template>
        </header>
        <nav class="bg-primary" v-if="!$route.meta.noMenu">
            <appMenu />
        </nav>
        <main class="main">
            <router-view></router-view>
            <drawerRoot />
        </main>
        <sessionPrompt />
    </div>
</template>

<script>
import appMenu from '@/components/appMenu.vue';
import icon from 'common-vue/icon';
import drawerRoot from 'common-vue/drawerRoot';
import { format } from './utils/dateUtil';
import logoImg from '@/assets/logo.jpg';
import sessionPrompt from '@/components/sessionPrompt.vue';
import onInteraction from '@/directives/onInteraction';
import session from '@/store/session';

export default {
    components: {
        appMenu,
        icon,
        drawerRoot,
        sessionPrompt
    },
    directives: { onInteraction },
    computed: {
        logoImg: () => logoImg,
        userName() {
            const user = this.$store.state.srcUser;
            return user
                ? `${user.userId} - ${this.$d(
                      user,
                      'user_*_Name',
                      user.userEngName
                  )}`.toUpperCase()
                : '';
        },
        loginAt() {
            const user = this.$store.state.srcUser;

            return format(
                user && user.loginAt ? user.loginAt : new Date(),
                'datetime'
            );
        }
    },
    methods: {
        updateSession: () => session.updateSession(),
        logout: () => session.logout()
    }
};
</script>
