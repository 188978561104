<template>
    <div class="border rounded m-4 p-4">
        {{ $t('login.logout.in.progress') }}
    </div>
</template>
<script>
export default {
    async created() {
        this.$store.commit('clearSession');
        this.$router.push({ name: 'login' });
    }
};
</script>
