const EVENTS = [
    'click',
    'mousemove',
    'mouseenter',
    'keydown',
    'scroll',
    'touchstart'
];

import throttle from 'lodash/throttle';
export default {
    bind(el, binding) {
        el.__onInteraction = throttle(() => {
            binding.value();
        }, 500);
        EVENTS.forEach((evt) => {
            document.addEventListener(evt, el.__onInteraction);
        });
    },
    unbind(el) {
        EVENTS.forEach((evt) => {
            document.removeEventListener(evt, el.__onInteraction);
        });
    }
};
