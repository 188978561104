/**
Usage

    <div v-has="'TEST_ADD'">...</div>
    <div v-has="['TEST_ADD', 'TEST_DELETE']">...</div>
    <div v-has="'TEST_*'">...</div>
    <div v-has="['TEST_*', 'USER_*']">...</div>
    <div v-has"['USER']">...</div>
*/

export default function handle(el, binding, vnode) {
    const has = vnode.context.$root.$store.getters['hasPvl'];
    if (!has(binding.value)) {
        // replace HTMLElement with comment node
        const comment = document.createComment(' ');
        Object.defineProperty(comment, 'setAttribute', {
            value: () => undefined
        });
        vnode.elm = comment;
        vnode.text = ' ';
        vnode.isComment = true;
        vnode.context = undefined;
        vnode.tag = undefined;
        vnode.data.directives = undefined;

        if (vnode.componentInstance) {
            vnode.componentInstance.$el = comment;
        }

        if (el.parentNode) {
            el.parentNode.replaceChild(comment, el);
        }
    }
}
