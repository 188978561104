import Vue from 'vue';
import App from './App.vue';
import createRouter from './router';
import store from './store';
import Config from 'common-vue/Config';
import { instance, get } from '@/utils/ajax';
import session from '@/store/session';
import setValueObj from 'common-vue/setValueObj';
import * as message from './store/messageStore';

function getLocale() {
    var param = Object.fromEntries(
        window.location.search
            .replace(/^\?/, '')
            .split('&')
            .map((s) => s.split('='))
    );
    if (param.locale && param.locale.match(/en|zh-HK/)) {
        return param.locale;
    }
    return 'en';
}

Vue.config.productionTip = false;

Config.set('ctx', process.env.BASE_URL);
Config.set('getMessage', message.getMessage);
Config.set('addMessages', message.addMessages);
session.interceptRequest(instance);
message.interceptRequest(instance);

(async function () {
    Vue.use(message.messagePlugin);

    let locale = getLocale();
    const result = await get('/api/init', { locale });
    if (result.success === true) {
        store.commit('setScrUser', result.dataMap.user);
        store.commit('setProfiles', result.dataMap.profiles);
        store.commit('setSystemParameters', result.dataMap.systemParameters);
        store.commit('setVersion', result.dataMap.version);
    } else {
        store.commit('setScrUser', undefined);
    }

    if (result.dataMap.user) {
        locale = result.dataMap.user.userDispLangCode === 'tc' ? 'zh-HK' : 'en';
    }
    store.commit('message/setLocale', locale);
    store.dispatch('message/loadDateFnsLocale', locale);
    store.commit('message/setMessages', result.dataMap.messages);

    const router = await createRouter();
    window.appRouter = router;
    window.gammonStore = store;

    //alreadly logined
    if (store.state.srcUser) {
        session.refreshToken();
        session.updateSession();
    }

    Vue.directive('setValueObj', setValueObj);
    new Vue({
        router,
        store,
        render: (h) => h(App)
    }).$mount('#app');
})();
