import { createInstance, get } from '@/utils/ajax';
import { getMessage } from 'common-vue/message';
import qs from 'qs';
import Config from 'common-vue/Config';
import _get from 'lodash/get';

const instance = createInstance();

const getExpiryStorageKey = () => Config.get('ctx') + '_expiryTime';
const getUserIdStorageKey = () => Config.get('ctx') + '_userId';

const store = {
    accessTokenExpiry: undefined,
    refreshTokenTimer: undefined,
    expiry: undefined,
    get expiryTime() {
        const val = window.localStorage.getItem(getExpiryStorageKey());
        return val !== undefined ? parseInt(val, 10) : undefined;
    },
    set expiryTime(val) {
        if (val === undefined) {
            window.localStorage.removeItem(getExpiryStorageKey());
            clearLogoutTimer();
        } else {
            window.localStorage.setItem(getExpiryStorageKey(), val);
            resetLogoutTimer();
        }
    },
    get userId() {
        return window.localStorage.getItem(getUserIdStorageKey());
    },
    set userId(val) {
        if (val === undefined) {
            window.localStorage.removeItem(getUserIdStorageKey());
        } else {
            window.localStorage.setItem(getUserIdStorageKey(), val);
        }
    },
    sessionPromptTimer: undefined,
    logoutTimer: undefined,
    events: { sessionPrompt: [], logout: [], sessionUpdated: [] },
    updateSessionDisabled: false
};

function updateToken(data) {
    const d = data || {};
    store.accessTokenExpiry = d.accessTokenExpiry;
    store.expiry = d.expiry;
    store.refreshTokenBefore = d.refreshTokenBefore;
    store.sessionPromptBefore = d.sessionPromptBefore;
    if (data) {
        clearTimeout(store.refreshTokenTimer);
        store.refreshTokenTimer = setTimeout(
            refreshToken,
            store.accessTokenExpiry * 1000 - store.refreshTokenBefore
        );
    } else {
        clearTimeout(store.refreshTokenTimer);
        store.refreshTokenTimer = undefined;
    }
}

async function refreshToken() {
    try {
        const resp = await instance({ url: '/api/refreshToken' });
        if (resp.data) {
            updateToken(resp.data);
            return { success: true };
        }
    } catch (e) {
        /* eslint-disable no-console */
        console.error('refresh Token failed', e);
    }
    updateToken();
    return { success: false };
}

function interceptRequest(inst) {
    return inst.interceptors.request.use(
        function (config) {
            updateSession();
            return config;
        },
        function (err) {
            return Promise.reject(err);
        }
    );
}

function trigger(evt) {
    if (store.events[evt]) {
        store.events[evt].forEach((cb) => cb());
    }
}

function clearLogoutTimer() {
    clearTimeout(store.logoutTimer);
    clearTimeout(store.sessionPromptTimer);
}

function resetLogoutTimer() {
    clearLogoutTimer();
    store.sessionPromptTimer = setTimeout(
        () => trigger('sessionPrompt'),
        store.expiryTime - new Date().getTime() - store.sessionPromptBefore
    );
    store.logoutTimer = setTimeout(
        () => logout(),
        store.expiryTime - new Date().getTime()
    );
}

function updateSession(val) {
    if (store.expiry && (val !== undefined || !store.updateSessionDisabled)) {
        if (val === undefined) {
            val = new Date().getTime() + store.expiry * 1000;
        }
        store.expiryTime = val;
        trigger('sessionUpdated');
    }
}

function disableUpdateSession(flag = true) {
    store.updateSessionDisabled = flag;
}

async function login({ username, password }) {
    let errMsg = undefined;
    try {
        const resp = await instance.post(
            '/api/login',
            qs.stringify({ username, password }),
            {
                headers: {
                    'Content-Type':
                        'application/x-www-form-urlencoded;charset=utf-8'
                }
            }
        );
        if (resp.data) {
            updateToken(resp.data);
            updateSession();
            const user = await get('/api/getUser');
            window.localStorage.setItem(getUserIdStorageKey(), user.userId);
            return { success: true, dataMap: { user } };
        }
    } catch (e) {
        /* eslint-disable no-console */
        console.error('login error', e);
        errMsg = _get(e, 'response.data.message');
    }
    return {
        success: false,
        dataMap: {
            errors: {
                server:
                    errMsg !== 'Unauthorized'
                        ? getMessage(errMsg)
                        : getMessage('login.error.username.password')
            }
        }
    };
}

async function logout() {
    try {
        await instance.get('/api/logout');
    } catch (e) {
        /* eslint-disable no-console */
        const { status } = e.response || {};
        if (status === 401 || status === 403) {
            console.log('already logged out');
        } else {
            console.error('logout error', e);
        }
    }
    store.expiryTime = undefined;
    store.userId = undefined;
    updateToken();
    disableUpdateSession(false);
    trigger('logout');
}

function on(evt, cb) {
    if (store.events[evt]) {
        store.events[evt].push(cb);
    }
}

function isEmpty(val) {
    return val === undefined || val === null;
}

window.addEventListener('storage', (evt) => {
    if (
        ('hidden' in document && !document.hidden) ||
        evt.newValue === evt.oldValue
    ) {
        return;
    }

    if (
        evt.key === getExpiryStorageKey() &&
        evt.newValue !== store.expiryTime
    ) {
        if (isEmpty(evt.newValue) && !!evt.oldValue) {
            logout();
        } else {
            updateSession(evt.newValue);
        }
    }

    if (evt.key === getUserIdStorageKey()) {
        if (!isEmpty(evt.newValue) && evt.oldValue !== evt.newValue) {
            location.reload();
        }
    }
});

export default {
    refreshToken,
    interceptRequest,
    login,
    logout,
    updateSession,
    on,
    disableUpdateSession
};
