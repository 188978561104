<template>
    <page>
        <p>Welcome</p>
    </page>
</template>

<script>
import { PrvlCode, PrvlActCode } from '@/constant';

import page from '@/components/page.vue';
export default {
    components: { page },
    created() {
        const hasPvl = this.$store.getters.hasPvl;
        if (hasPvl(PrvlCode.Dashboard.concat('_', PrvlActCode.Read))) {
            this.$router.push({ name: 'dashboard' });
        } else if (
            hasPvl(PrvlCode.CaseManagement.concat('_', PrvlActCode.Read))
        ) {
            this.$router.push({ name: 'case' });
        } else {
            this.$router.push({ name: 'report' });
        }
    }
};
</script>
