<style lang="scss" scoped>
@import 'style/vars';
$menu-width: 16rem;
$menu-width-collapsed: 3.357rem;
.menu {
    list-style: none;
    padding: 0;
    width: 100%;
}
.menu-link {
    display: flex;
    align-items: flex-start;
    /* padding: $spacer $spacer * 1.5; */
    padding: $spacer / 2;
    color: $white;
    font-size: $font-size-lg;
    position: relative;
    text-decoration: none;
}
.menu-link ::v-deep .icon {
    flex-shrink: 0;
}
.menu-link:not(.home-link).router-link-active,
.home-link.router-link-exact-active {
    background-color: $white;
    color: $primary;
}

.menu-group.has-active {
    background-color: darken($primary, 10%);
}
.menu-link:not(.home-link).router-link-active:before,
.home-link.router-link-exact-active:before,
.menu-group.has-active:before {
    content: '';
    display: block;
    width: 0;
    height: 100%;
    border-left: 0.5rem solid $blue;
    position: absolute;
    top: 0;
    left: 0;
}

.menu-sub .menu-link.router-link-active:before {
    display: none;
}

.menu-sub .menu-link.no-icon {
    padding-left: 2.25rem + 0.5rem + 1rem;
}
.collapsed ::v-deep .menu-sub .menu-link.no-icon {
    padding: 0.5rem 1rem;
}
.menu-wrap {
    width: $menu-width;
    transition: width 300ms $ease;
}
/* .menu-wrap.collapsed > .menu > li { */
/*     overflow: hidden;               */
/* }                                   */
.menu-text {
    /* white-space: nowrap;*/
    opacity: 1;
    text-align: start;
}
.collapsed {
    width: $menu-width-collapsed;
}
.collapsed ::v-deep .menu-text {
    display: none;
    opacity: 0;
    white-space: nowrap;
}
.menu-sub {
    display: none;
}
.menu-group {
    cursor: pointer;
    background: none;
    border: none;
    width: 100%;
}
.menu-group:focus {
    outline: none;
}
.menu-group ::v-deep > .menu-group-expand-icon {
    transform: rotate(180deg);
}
.collapsed ::v-deep .sub-expanded {
    background: darken($primary, 10%);
}
.sub-expanded ::v-deep > .menu-group > .menu-group-expand-icon {
    transform: rotate(-90deg);
}
.sub-expanded ::v-deep > .menu-group + .menu-sub {
    display: block;
}

.collapsed ::v-deep .menu-group-expand-icon {
    display: none;
}
.collapsed ::v-deep .menu-sub {
    position: fixed;
    top: 0;
    left: $menu-width-collapsed;
    z-index: 10;
    width: auto;
    background: lighten($primary, 10%);
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
}
.collapsed ::v-deep .menu-sub .menu-text {
    opacity: 1;
    display: block;
}
.menu-separator {
    background: $light;
}
</style>

<template>
    <div
        class="menu-wrap"
        :class="{ collapsed: collapsed }"
        v-click-outside="clickOutside"
    >
        <div class="d-flex justify-content-end">
            <button class="btn p-0" @click="toggleMenu">
                <icon class="icon-lg text-white m-2" src="menu.svg" />
            </button>
        </div>
        <ul class="menu">
            <li
                v-for="(m, mi) in menuData"
                :key="mi"
                v-has="getMenuItemPvl(m)"
                :class="{
                    'position-relative menu-group-wrap': m.group,
                    'sub-expanded': m.group && subMenu[m.key]
                }"
            >
                <hr class="menu-separator" v-if="m.separator" />
                <router-link
                    v-else-if="!m.group"
                    class="menu-link"
                    :class="m.linkClass"
                    tag="a"
                    :to="m.to"
                    :title="m.labelKey ? $t(m.labelKey) : m.label"
                >
                    <icon class="icon-lg mr-3" :src="m.icon" />
                    <span class="menu-text">
                        {{ m.labelKey ? $t(m.labelKey) : m.label }}
                    </span>
                </router-link>
                <button
                    v-else
                    class="menu-group menu-link"
                    :id="'menu-group-' + mi"
                    :class="{
                        'has-active': routeMatch(m.children.map((c) => c.to))
                    }"
                    @click="toggleSubMenu(m.key, mi)"
                >
                    <icon class="icon-lg mr-3 menu-group-icon" :src="m.icon" />
                    <span class="menu-text">
                        {{ m.labelKey ? $t(m.labelKey) : m.label }}
                    </span>
                    <icon
                        class="icon-lg ml-auto mr-n2 menu-group-expand-icon"
                        src="keyboard_arrow_left.svg"
                    />
                </button>

                <ul
                    class="menu menu-sub"
                    :id="'menu-sub-' + mi"
                    v-if="m.children && m.children.length"
                >
                    <li
                        v-for="(c, ci) in m.children"
                        :key="ci"
                        v-has="getMenuItemPvl(c)"
                    >
                        <router-link
                            class="menu-link"
                            :class="[c.linkClass, c.icon ? '' : 'no-icon']"
                            tag="a"
                            :to="c.to"
                            :title="c.labelKey ? $t(c.labelKey) : c.label"
                        >
                            <icon
                                v-if="c.icon"
                                class="icon-lg mr-3"
                                :src="c.icon"
                            />
                            <span class="menu-text">
                                {{ c.labelKey ? $t(c.labelKey) : c.label }}
                            </span>
                        </router-link>
                    </li>
                </ul>
            </li>
        </ul>
    </div>
</template>

<script>
import icon from 'common-vue/icon';
import clickOutside from 'common-vue/clickOutside';
import has from '@/directives/has';
import { PrvlActCode, PrvlCode } from '@/constant';

function addPrvlSuffix(pvl, suffix) {
    return pvl.concat('_', suffix);
}

const menuData = [
    {
        pvl: addPrvlSuffix(PrvlCode.Dashboard, PrvlActCode.Read),
        to: '/dashboard',
        icon: 'dashboard.svg',
        labelKey: 'menu.dashboard'
    },
    {
        pvl: addPrvlSuffix(PrvlCode.CaseManagement, PrvlActCode.Read),
        to: '/case',
        icon: 'assignment.svg',
        labelKey: 'menu.case'
    },
    { separator: true },
    {
        to: '/report',
        icon: 'description.svg',
        labelKey: 'menu.report'
    },
    { separator: true },
    {
        group: true,
        key: 'setup',
        labelKey: 'menu.setup',
        icon: 'settings.svg',
        children: [
            {
                pvl: addPrvlSuffix(PrvlCode.UserSetup, PrvlActCode.Read),
                to: '/user',
                labelKey: 'menu.user'
            },
            {
                pvl: addPrvlSuffix(PrvlCode.SiteSetup, PrvlActCode.Read),
                to: '/site',
                labelKey: 'menu.site'
            },
            {
                pvl: addPrvlSuffix(
                    PrvlCode.CannedOptionSetup,
                    PrvlActCode.Read
                ),
                to: '/cannedOption',
                labelKey: 'menu.cnndOpt'
            },
            {
                pvl: addPrvlSuffix(PrvlCode.SystemSetup, PrvlActCode.Read),
                to: '/systemSetup',
                labelKey: 'menu.system'
            }
        ]
    },
    {
        pvl: addPrvlSuffix(PrvlCode.MyProfile, PrvlActCode.Read),
        to: '/myProfile',
        icon: 'person.svg',
        labelKey: 'menu.myProfile'
    }
];

export default {
    components: { icon },
    directives: { clickOutside, has },
    data: () => ({
        subMenu: {}
    }),
    computed: {
        collapsed() {
            return this.$store.state.menuCollapsed;
        },
        menuData: () => menuData
    },
    created() {
        this.$router.afterEach(() => {
            if (this.collapsed) {
                this.subMenu = {};
            }
        });
    },
    methods: {
        toggleMenu() {
            this.$store.commit('setMenuCollapsed', !this.collapsed);
            this.$root.$el.classList.toggle(
                'app-menu-collapsed',
                this.collapsed
            );
            if (this.collapsed) {
                this.subMenu = {};
            }
        },
        toggleSubMenu(item, index) {
            if (this.collapsed) {
                this.subMenu = {};
            }
            this.$set(this.subMenu, item, !this.subMenu[item]);
            this.$nextTick(() => {
                const menu = document.querySelector('#menu-sub-' + index);
                const group = document.querySelector('#menu-group-' + index);
                const mb = menu.getBoundingClientRect();
                const gb = group.getBoundingClientRect();
                const vh = document.documentElement.clientHeight;
                let top = gb.top - Math.max(gb.top + mb.height - vh + 16, 0);
                menu.style.top = top + 'px';
            });
        },
        clickOutside() {
            if (this.collapsed) {
                this.subMenu = {};
            }
        },
        routeMatch(arr) {
            return arr.some((r) => this.$route.path.indexOf(r) === 0);
        },
        getMenuItemPvl(m) {
            if (m.group === true) {
                return m.children.map((c) => c.pvl || '*');
            }
            return m.pvl || '*';
        }
    }
};
</script>
