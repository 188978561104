<template>
    <div class="d-flex justify-content-center align-items-center p-5">
        <spinner />
    </div>
</template>

<script>
import spinner from 'common-vue/spinner';
export default {
    components: { spinner }
};
</script>
