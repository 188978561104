import isString from 'lodash/fp/isString';
import isArray from 'lodash/fp/isArray';

const toArray = function (val) {
    return isString(val)
        ? [val]
        : isArray(val)
        ? val.map((v) => v.toString())
        : [];
};

const endWithWildcard = /([^*]+)\*$/;
const matchCodeList = (codeList) => (code) => {
    if (code === '*') {
        return true;
    }
    const m = code.match(endWithWildcard);
    return m
        ? codeList.find((c) => c.startsWith(m[1]))
        : codeList.indexOf(code) !== -1;
};

const match = (code, list) => {
    return toArray(code).some(matchCodeList(list));
};

export default match;
