import isString from 'lodash/isString';
import isDate from 'lodash/isDate';
import isNumber from 'lodash/isNumber';
import isArray from 'lodash/isArray';
import _format from 'date-fns/format';
import _parse from 'date-fns/parse';
import parseISO from 'date-fns/parseISO';
import startOfDay from 'date-fns/startOfDay';
import startOfMinute from 'date-fns/startOfMinute';

const FORMATS = {
    display: 'dd/MM/yyyy',
    entry: 'ddMMyyyy',
    datetime: 'dd/MM/yyyy HH:mm:ss',
    datetimeHhmm: 'dd/MM/yyyy HH:mm',
    datetimeInput: 'ddMMyyyy HH:mm'
};

function getDefaultFormat(format) {
    return FORMATS[format] || format;
}

export function numberToDate(value) {
    if (isNumber(value)) {
        //assume seconds as in Instant(Java)
        return new Date(value * 1000);
    }
    return undefined;
}

export function dateToNumber(value) {
    if (isDate(value)) {
        return value.getTime() / 1000;
    }
    return value;
}

export function today() {
    return dateToNumber(startOfDay(new Date()));
}

export function currentMinute() {
    return dateToNumber(startOfMinute(new Date()));
}

export function format(value, format = 'display') {
    //timestamp or ISO-date
    if (isString(value)) {
        value = new Date(value);
    }
    if (isNumber(value)) {
        value = numberToDate(value);
    }
    if (isArray(value) && value.length === 3) {
        value = new Date(value[0], value[1], value[2]);
    }

    if (isDate(value)) {
        return _format(value, getDefaultFormat(format));
    }
    return '';
}

export function formatLocalize(value, formatStr, opt = {}) {
    let variant;
    if (window.gammonStore && window.gammonStore.state.message) {
        const { locale, dateFnsLocale } = window.gammonStore.state.message;
        if (locale === 'zh-HK') {
            variant = 'zh';
            opt = { ...opt, locale: dateFnsLocale };
        }
    }
    return format(value, getDefaultFormat(formatStr, variant), opt);
}

export function parse(value, format = 'entry', base = new Date()) {
    if (!value) {
        return undefined;
    }
    if (isDate(value)) {
        return value;
    }
    if (isNumber(value)) {
        return numberToDate(value);
    }
    let d = parseISO(value);
    if (isValidDate(d)) {
        return d;
    }
    format = getDefaultFormat(format);
    return _parse(value, format, base);
}

export function isValidDate(d) {
    return d && d instanceof Date && !isNaN(d.getTime());
}

export function isWithinRange(d, min, max) {
    d = parse(d);
    min = parse(min);
    max = parse(max);
    if (isValidDate(min) && d < min) {
        return false;
    }
    if (isValidDate(max) && d > max) {
        return false;
    }
    return true;
}
