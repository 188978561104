// import storage from '@/storage';
import { instance } from '@/utils/ajax';
import Config from 'common-vue/Config';

const state = {
    locale: 'en',
    messages: {},
    dateFnsLocale: undefined
};

const addMessages = (obj, override) => {
    Object.entries(obj).forEach(([k, v]) => {
        if (!state.messages[k] || override) {
            state.messages[k] = v;
        }
    });
};

const getMessage = (key, ...args) => {
    const msg = state.messages[key];
    if (!msg) {
        return key;
    }
    return args.reduce(
        (s, a, i) => s.replace(new RegExp(`\\{${i}\\}`, 'g'), a),
        msg
    );
};

/*
 * e.g.
 *   name: userName; => name: userNameChi
 *   name: user_*_Name => name: userChiName
 */
function getDesp(model, name, defaultValue) {
    let languageCode = 'Eng';
    const locale = state.locale;
    if (locale === 'zh-HK') {
        languageCode = 'Chi';
    }
    name =
        name.indexOf('_*_') > -1
            ? name.replace('_*_', languageCode)
            : name + languageCode;
    return model[name] || defaultValue;
}

async function getDateFnsLocale(locale) {
    if (locale === 'en') {
        return undefined;
    }
    if (locale === 'zh-HK') {
        locale = 'zh-TW';
    }

    const result = await import(
        /* webpackInclude: /(zh-TW)/ */
        /* webpackChunkName: "dateFnsLocale" */
        /* webpackMode: "lazy-once" */
        /* webpackPrefetch: true */
        /* webpackPreload: true */
        'date-fns/locale/' + locale + '/index'
    );
    return result.default;
}

const getters = {
    getMessage: () => getMessage,
    getDesp: () => getDesp
};

Config.set('getDateFnsLocale', () => state.dateFnsLocale);

const actions = {
    async loadDateFnsLocale({ commit }, locale) {
        const dateFnsLocale = await getDateFnsLocale(locale);
        commit('setDateFnsLocale', dateFnsLocale);
    },
    async changeLocale({ dispatch, commit, state }, locale) {
        const isLocaleChanged = locale !== state.locale;
        commit('setLocale', locale);
        if (isLocaleChanged) {
            //let prefix = ['portal.'];
            const result = await instance.get('/api/messages', {
                //params: { prefix: prefix.join(',') }
            });
            commit('addMessages', result);
        }
        await dispatch('loadDateFnsLocale', locale);
    }
};

const mutations = {
    setMessages(state, messages) {
        state.messages = messages;
    },
    addMessages(state, messages) {
        state.messages = {
            ...state.messages,
            ...messages
        };
    },
    setLocale(state, locale) {
        state.locale = locale;
        // storage.setItem('locale', locale);
    },
    setDateFnsLocale(state, val) {
        state.dateFnsLocale = val;
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};

const messagePlugin = {
    install: async (Vue) => {
        Vue.prototype.$t = getMessage;
        Vue.prototype.$d = getDesp;
        return true;
    }
};

function interceptRequest(instance) {
    instance.interceptors.request.use(
        function (request) {
            request.headers['Accept-Language'] = state.locale;
            return request;
        },
        function (error) {
            return Promise.reject(error);
        }
    );
}

export { getMessage, getDesp, messagePlugin, interceptRequest, addMessages };
