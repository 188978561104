import Vue from 'vue';
import Vuex from 'vuex';
import matchCodeList from './matchPvlCodeList';
import { get } from '@/utils/ajax';
import message from './messageStore';

Vue.use(Vuex);

export default new Vuex.Store({
    state: () => ({
        srcUser: undefined,
        profiles: undefined,
        systemParameters: undefined,
        version: undefined,
        menuCollapsed: window.sessionStorage.getItem('menuCollapsed') === 'true'
    }),
    mutations: {
        setScrUser(state, val) {
            state.srcUser = val;
        },
        setProfiles(state, val) {
            state.profiles = val;
        },
        setSystemParameters(state, val) {
            state.systemParameters = val;
        },
        setVersion(state, val) {
            state.version = val;
        },
        clearSession(state) {
            state.srcUser = undefined;
        },
        setMenuCollapsed(state, val) {
            state.menuCollapsed = val;
            window.sessionStorage.setItem(
                'menuCollapsed',
                val ? 'true' : 'false'
            );
        }
    },
    getters: {
        userRoleList: (state) => {
            return state.srcUser?.roles || [];
        },
        userSiteList: (state) => {
            return state.srcUser?.sites || [];
        },
        userDfltSiteKey: (state) => {
            return state.srcUser?.userDfltSiteKey;
        },
        userGridDspRecNum: (state) => {
            const userGridDspRecNum = state.srcUser?.userGridDspRecNum;
            return [50, 100, 200].includes(userGridDspRecNum)
                ? userGridDspRecNum
                : 50;
        },
        userLocale: (state) => {
            return state.srcUser?.userDispLangCode === 'tc' ? 'zh-HK' : 'en';
        },
        hasProfile:
            (state) =>
            (...profiles) => {
                return (state.profiles || []).some((v) => profiles.includes(v));
            },

        /*
         * code can be String or string array of pvl code, support ending wildcard
         * e.g.
         *   code = 'TEST_ADD'
         *   code = ['TEST_ADD', 'TEST_DELETE']
         *   code = 'TEST_*'
         *   code = ['TEST_*', 'USER_*']
         */
        hasPvl: (state) => (code) => {
            if (!state.srcUser) {
                return false;
            }
            let privileges = state.srcUser.privileges;
            return matchCodeList(code, privileges);
        }
    },
    actions: {
        async reloadScrUser(ctx) {
            const scrUser = await get('/api/getUser');
            ctx.commit('setScrUser', scrUser);
        }
    },
    modules: { message }
});
