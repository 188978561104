<style scoped>
.error-box {
    width: 100%;
}
</style>

<template>
    <div class="alert alert-danger error-box mr-3" v-if="dispErrBox">
        <template v-if="errorList && errorList.length > 0">
            <ul class="mb-0 ml-n4">
                <li v-for="(i, idx) in errorList" :key="idx">
                    {{ i.message || getMessage(i.messageKey, i.messageArgs) }}
                </li>
            </ul>
        </template>
        <template v-else-if="!!errors.server">
            {{ errors.server }}
        </template>
        <template v-else-if="!!errors.error">
            {{ errors.error }}
        </template>
        <template v-else-if="errors.message || errors.messageKey">
            {{
                errors.message ||
                getMessage(errors.messageKey, errors.messageArgs)
            }}
        </template>
    </div>
</template>

<script>
import isArray from 'lodash/isArray';
import isEmpty from 'lodash/isEmpty';

export default {
    props: {
        errors: { type: [Object, Array] }
    },
    computed: {
        dispErrBox() {
            return (
                !isEmpty(this.errors) &&
                (!isEmpty(this.errors.errorList) ||
                    this.errors.server ||
                    this.errors.error ||
                    this.errors.message ||
                    this.errors.messageKey)
            );
        },
        errorList() {
            if (!this.isErrorEmpty) {
                return isArray(this.errors)
                    ? this.errors
                    : this.errors.errorList;
            }
            return null;
        }
    },
    methods: {
        getMessage(msgKey, msgArgs = []) {
            return this.$t(msgKey, ...msgArgs);
        }
    }
};
</script>
