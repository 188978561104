<style lang="scss" scoped>
@import 'style/vars';

.login-page {
    background-color: $primary;
    height: 100%;
}

.field-icon {
    background: $gray-200;
    display: inline-flex;
    align-items: center;
    border: 1px solid $input-border-color;
    border-right: none;
    padding: 0 0.5rem;
    border-top-left-radius: $input-border-radius;
    border-bottom-left-radius: $input-border-radius;
}

.toggle-password-visibility {
    background: white;
    border: 1px solid $input-border-color;
    border-left: none;
    padding: 0 0.5rem;
    border-top-right-radius: $input-border-radius;
    border-bottom-right-radius: $input-border-radius;
}

.toggle-password-visibility.visible > .icon {
    color: blue;
}

.nav-title {
    font-size: $h3-font-size;
    font-weight: 500;
}

.forgot-pw {
    color: $primary;
    cursor: pointer;
}

.version {
    position: fixed;
    bottom: 3px;
    right: 3px;
    color: $primary;
}
</style>
<template>
    <div class="px-4 login-page pt-5">
        <div class="card mx-auto" style="width: 500px; max-width: 100%">
            <div class="card-header d-flex justify-content-center">
                <div class="nav-title text-primary d-flex align-items-center">
                    {{ $t('login.login') }}
                </div>
            </div>
            <div class="card-body">
                <form>
                    <field
                        class="wrap"
                        :label="$t('login.loginid')"
                        :required="true"
                        :error="errors.username"
                    >
                        <div class="d-flex">
                            <div class="field-icon">
                                <icon src="account.svg" />
                            </div>
                            <input
                                class="form-control flex-1"
                                :class="{ 'border-danger': errors.username }"
                                v-model="form.username"
                                @keydown.enter="$refs.password.focus"
                            />
                        </div>
                    </field>
                    <field
                        class="wrap"
                        :label="$t('login.password')"
                        :required="true"
                        :error="errors.password"
                    >
                        <div class="d-flex">
                            <div class="field-icon">
                                <icon src="lock.svg" />
                            </div>
                            <input
                                class="form-control flex-1"
                                :type="!!showPassword ? 'text' : 'password'"
                                :class="{ 'border-danger': errors.password }"
                                ref="password"
                                v-model="form.password"
                                @keydown.enter="login"
                            />
                            <button
                                type="button"
                                class="toggle-password-visibility"
                                :class="{ visible: !!showPassword }"
                                @click="showPassword = !showPassword"
                            >
                                <icon src="visibility.svg" />
                            </button>
                        </div>
                    </field>
                </form>
                <div
                    v-if="errors.server"
                    class="text-center text-danger alert alert-danger mb-2 mx-2"
                >
                    {{ errors.server }}
                </div>
                <div class="d-flex px-2">
                    <savingButton
                        :state="saveStatus"
                        class="btn btn-lg w-100 btn-primary mt-2"
                        @click="login"
                        @saved="saved"
                    >
                        {{ $t('login.login') }}
                    </savingButton>
                </div>
                <div class="forgot-pw mt-2 px-2" @click="forgotPw()">
                    {{ $t('login.forgot.password') }}?
                </div>
            </div>
        </div>
        <div class="version">{{ $store.state.version }}</div>

        <frgtPwdModal ref="frgtPwdModal" />
    </div>
</template>

<script>
import field from 'common-vue/field';
import savingButton from 'common-vue/savingButton';
import { validate } from 'common-vue/validationUtil';
import icon from 'common-vue/icon';
// import logoImg from '@/assets/logo6_sm_optimized.png';
import frgtPwdModal from '@/components/frgtPwdModal.vue';

import session from '@/store/session';

export default {
    components: { field, savingButton, icon, frgtPwdModal },
    data: () => ({
        form: {
            username: undefined,
            password: undefined
        },
        saveStatus: undefined,
        errors: {},
        showPassword: false
    }),
    methods: {
        async login() {
            this.errors = {};
            const v = validate(this.errors, this.form, {
                username: { required: true },
                password: { required: true }
            });
            if (v.hasError) {
                this.errors = v.errors;
                return;
            }
            this.saveStatus = 'saving';
            const result = await session.login(this.form);
            if (result.success) {
                result.dataMap.user.loginAt = new Date();
                this.$store.commit('setScrUser', result.dataMap.user);
                this.refreshDisplayLanguage();
                this.saveStatus = 'saved';
            } else {
                /* eslint-disable no-console */
                console.error('result', result);
                this.errors = result.dataMap.errors;
                this.saveStatus = undefined;
            }
        },
        saved() {
            this.$router.push('/');
        },
        forgotPw() {
            this.$refs.frgtPwdModal.prompt({});
        },
        async refreshDisplayLanguage() {
            await this.$store.dispatch(
                'message/changeLocale',
                this.$store.getters.userLocale
            );
        }
    }
};
</script>
