<template>
    <modal :show="show" type="confirm" @ok="ok" @cancel="cancel">
        <template #content>
            {{ $t('login.timeout') }}
        </template>
    </modal>
</template>

<script>
import modal from 'common-vue/modal';
import session from '@/store/session';

export default {
    components: { modal },
    data: () => ({ show: false }),
    created() {
        session.on('sessionPrompt', () => {
            session.disableUpdateSession(true);
            this.show = true;
        });
        session.on('sessionUpdated', () => {
            if (this.show) {
                session.disableUpdateSession(false);
                this.show = false;
            }
        });
        session.on('logout', () => {
            this.show = false;
            this.$router.push('/logout');
        });
    },
    methods: {
        ok() {
            session.disableUpdateSession(false);
            session.updateSession();
            this.show = false;
        },
        cancel() {
            this.show = false;
        }
    }
};
</script>
